import React from 'react';
import Helmet from 'react-helmet';
import styles from './Layout.module.scss';

const Layout = ({ children, title, description }) => (
  <div className={styles.layout}>
    <Helmet>
      <html lang="en" />
      <title>{title}</title>
      <meta name="description" content={description} />
      {/* Linkedin related fields */}
      <meta property='og:title' content="Blog - Aston Yao"/>
      <meta property='og:image' content="https://astonyao.com/media/YAO.png"/>
      <meta property='og:description' content="I am a Software Engineer based in Melbourne."/>
    </Helmet>
    {children}
  </div>
);

export default Layout;
